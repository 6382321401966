
.hexa-card {
    width: 31.25rem;
    min-height: 5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background: #D7E4EC;
    border-radius: 0.5rem;
    border: none;
}

.hexa-card:hover {
    background: #98C4DF !important;
    cursor: pointer;
}

.card-body{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hexa-card__title {
    font-size: 1rem;
    font-weight: 600;
    width: 28rem;
    align-items: center;
    color: #0171BB;
}

.hexa-card__body {
    font-weight: normal;
    margin-top: 0.2rem;
    font-size: 0.875rem;
    align-items: center;
    color: #616161;
}