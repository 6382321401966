.search__text-icon
{
    position: absolute;
    top: 7px;
    right: 14px;
    fill: var(--normal-text-color);
}
.search__box
{
    border-style: solid;
    border-width: 1px;
    border-color: var(--border-control-color) ;
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    
    .search__border-left
    {
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: var(--border-control-color) ;
    }

    .search__dropdown{
        flex-grow: 1;
        .btn{
            box-shadow: 0 0;
            display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: center;
        
        }

        .dropdown-toggle
        {
            font-size: 14px;
            width: 100%;
            background-color: var(--container-background-color);
            color: var(--sidebar-normal-text-color);           
        }

        .dropdown-toggle:focus
        {
            //border-color: #000000;
            //color: #000000 !important;
        
        }

        .dropdown-toggle:focus::after
        {
            color: var(--sidebar-normal-text-color) !important;
        }

        .search__dropdown-menu
        {
            background-color: var(--container-background-color);
            font-size: 14px;
            text-align: left;
            //width: 200%;

            .dropdown-item
            {
                background-color: var(--container-background-color);
                color: var(--sidebar-normal-text-color);
                padding-left: 15px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                svg{
                    display: none;
                }
            }

            .dropdown-item.active
            {
                background-color: var(--focus-background-color);
                color: var(--highlight-text-color);
                svg{
                    display: flex;
                }

            }

            .dropdown-item:hover
            {
                background-color: var(--focus-background-color);
                color: var(--highlight-text-color);
            }
        }
    }
    .search__textbox
    {
        border-style: none;
        color: var(--normal-text-color);
    }
}
.search__box:hover{
    border-color: var(--border-control-select-color);
}