.password-eye
{
    position: absolute;
    top: 8px;
    right: 14px;
}

.password-eye:hover
{
    cursor: pointer;
}