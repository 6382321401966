.page-head-select
{
    border-radius: 8px;
    height: 32px;
    font-size: 0.8rem;
}

.selection-cell input {
  margin-top: 0.125rem !important;
  border: 1px solid #9D9D9D;
  width: 18px;
  height: 18px;
}



