.modal__main
{
    .modal-body
    {
        padding-top: 16px;
        padding-right: 24px;
        padding-left: 24px;
        padding-bottom: 24px;
        .form-group
        {
            margin-bottom: 7px;
        }
        color: #616161;
    }

    .modal-sm {
        max-width: 400px !important;
    }
}
.modal__overlay
{
    z-index: 1040 !important;
}
.modal__overlay-top
{
    z-index: 1051 !important;
}
.modal__form-row
{
    margin-top: 17px;
    margin-bottom: 23px;
}
.modal__header-no-border
{
    border-bottom-style: none;
    padding-bottom: 0px;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
}
.modal__title
{
    margin-bottom: 19px;
    font-size: 24px;
}
.modal__footer-full
{
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
}
.modal__footer-no-border
{
    border-top-style: none;
    padding-top: 0px;
}

.modal__footer-button
{
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 14px;
}
.modal__btn-margin-left
{
    margin-left: 8px;
}
.modal__btn-margin-right
{
    margin-right: 8px;
}

.modal__form-label-text
{
    font-size: 14px;
    color:var(--normal-text-color);
    margin-bottom: 4px;
}