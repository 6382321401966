.daily-stat__main-box
{
    background-color: var(--container-background-color);
    border-radius: 16px;
    padding-top: 15px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 11px;
}

.daily-stat__menu_btn{
    font-size: 14px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-control-color);
    background-color: var(--container-background-color);
    color: var(--normal-text-color);
    width: 144px;
    height: 32px;
}

.daily-stat__menu_btn.selected{
    border-width: 2px;
    border-color: var(--highlight-text-color);
    color: var(--highlight-text-color);
    background-color: var(--focus-background-color);
}

.daily-stat__menu_btn:not(.selected):hover{

    color: var(--highlight-text-color);
    background-color: var(--focus-background-color);
}