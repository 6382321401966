$primary:#0171BB;
$secondary: #F6A41D;
$danger: #D05353;
$success: #16C366;
$info:#5AC8FA;
$warning: #FFB023;
$light: rgba(146,171,207,0.1);



//bootstrap input styling
$input-color:#000000;
$input-border-color: #CACACA;
$input-focus-border-color:#000000;
$input-focus-box-shadow: 0 0;
$btn-border-radius: 8px;
$btn-border-width:2px;
$input-font-size:14px;

$input-disabled-bg: #C9C9C9;

