.cart-product__main-box
{
    background-color: var(--container-background-color);
    border-radius: 16px;
    padding-top: 15px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 11px;
}

.cart-product__brand{
    font-size: 18px;
    font-weight: 400;
    color:var(--highlight-text-color);
}


