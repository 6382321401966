.date-period-filter__dropdown
{   
    height: 100%;
    
    .date-period-filter__main-btn{
        box-shadow: 0 0;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        height: 100%;
    }

    .date-period-filter__main-btn::after{
        display: none;
    }

    .date-period-filter__main-icon
    {
        fill: var(--normal-text-color);
        margin-left: 0px;
        margin-right: 22px;
    }

    .dropdown-toggle
    {       
        border-radius: 4px;
        background-color: var(--container-background-color);
        color: var(--sidebar-normal-text-color);
        border-color: var(--border-control-color);
        border-width: 1px;
        
    }

    .dropdown-toggle:focus
    {
        border-color: var(--border-control-select-color);
        //color: #000000 !important;      
    }

    .date-period-filter__period-menu
    {
        background-color: var(--container-background-color);
        font-size: 14px;
        text-align: left;
        width: 432px;
        padding: 15px;

        .date-period-filter__menu-box
        {
            .date-period-filter__select-btn
            {
                color: var(--normal-text-color);
                font-size: 14px;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
            }
            
            .date-period-filter__select-menu{
                background-color: var(--container-background-color);
                font-size: 14px;
                text-align: left;

                .dropdown-item
                {
                    background-color: var(--container-background-color);
                    color: var(--sidebar-normal-text-color);
                    padding-left: 15px;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    svg{
                        display: none;
                    }
                }
        
                .dropdown-item.active
                {
                    background-color: var(--focus-background-color);
                    color: var(--highlight-text-color);
                    svg{
                        display: flex;
                    }
        
                }
        
                .dropdown-item:hover
                {
                    background-color: var(--focus-background-color);
                    color: var(--highlight-text-color);
                }
        
                
            }
            .date-period-filter__date
            {
                margin-top: 20px;

                .date-period-filter__label
                {
                    font-size: 14px;
                    color:var(--normal-text-color) ;
                    margin-bottom: 8px;
                }
            }
        }
    }  

    @media (max-width: 518px) {
        .date-period-filter__period-menu{
            width: auto;
        }
    }
}

.date-picker__group{
    border-style: solid;
    border-color: var(--border-control-color) ;
    border-width: 1px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;

    .date-picker__icon
    {
        fill:var(--normal-text-color);
        margin-left: 19px;
        margin-right: 19px;
        
    }

    .react-datepicker-wrapper
    {
        width: 100%;
    }
   
}
.date-picker__group.disabled{
    background-color:var(--disabled-textbox-background-color);
    border-color: var(--disabled-textbox-border-color);

    .date-picker__input
    {
        background-color:var(--disabled-textbox-background-color);         
    }
}

.date-picker__group:not(.disabled):hover{
    border-color: var(--border-control-select-color);
}

.date-picker__input
{
    width: 100%;
    border-style: none !important;  
    border-width: 0px !important;
    border-radius: 4px;
    color: var(--normal-text-color);
}

.date-picker__input:hover
{
    border-style: dotted !important;  
    border-width: 0px  !important;
}

.date-picker__input:focus
{
    outline: none;
}