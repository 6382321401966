:root {

    --page-border-color:#D7E4EC;
    --page-title-text-color:#000000;

    --container-background-color:#ffffff;
    --icharm-highlight-text-color:#FA6841;
    --highlight-text-color:#0171BB;
    --focus-background-color:#D7E4EC;
    --normal-text-color:#616161;
    --danger-text-color: #EB2E38;
    --danger-text-color-bold:#ad0009;
    --highlight2-text-color:#000000;
    --highlight3-text-color:#F6A41D;
    --disabled-icon-color: #cfcfcf;
    --disabled-textbox-background-color:#C9C9C9;
    --disabled-textbox-border-color: #C9C9C9;
    
    --header-username-text:#808080;
    --header-username-border-color:#666666;
    --header-menu-hover-color:#dadada;

    --top-nav-header-background-color:#e8e8e8;
    --top-nav-header-border-color:#ffffff;

    --sidebar-background-color:#ffffff;
    --sidebar-selected-background-color:#D7E4EC;
    --sidebar-normal-text-color:#616161;
    --sidebar-highlight-text-color:#0171BB;

    --table-header-text-color:#616161;
    --table-row-text-color:#000000;

    --border-control-color:#CACACA;
    --border-control-select-color:#000000;

    --badge-purple-color: #BC45E6;
    
}