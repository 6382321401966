.attached-badge{
    min-width: 90px;
    background-color: var(--focus-background-color);
    color: var(--highlight-text-colo);
    min-height: 24px ;
    font-size: unset;
    border-radius: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 0px;
    margin-right: 8px;
}

.attached-item{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 2px;
}

.attached-text{
    color: var(--highlight-text-color);
    font-size: 14px;
    font-weight: 400;
}

.attached-text:hover, .attached-close:hover{
    cursor: pointer;
}

.attached-close{
    fill:var(--highlight2-text-color);
    margin-left: 8px;
}
.ReactModal__Overlay--after-open{
    z-index: 1060 !important;
}