
.hexa-logo {
    width: 16px;
    height: 16px;
    align-self: center;
}

.hexa-x-logo {
    width: 10px;
    height: 10px;
    position: absolute;
    margin: 10px;
    right: 0px;
}

.hexa-attach-logo{
    width: 24px;
    height: 16.02px;
    align-self: center;
}