.page-head-dropdown 
{
    .btn{
        box-shadow: 0 0;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
       
    }

    .dropdown-toggle
    {
        border-radius: 8px;
        height: 32px;
        font-size: 14px;
        width: 233px;
        background-color: var(--container-background-color);
        color: var(--sidebar-normal-text-color);
        border-color: var(--border-control-color);
        border-width: 1px;
        overflow:hidden;
        
    }

    .dropdown-toggle:focus
    {
        border-color: var(--border-control-select-color);
        //color: #000000 !important;
       
    }

    .dropdown-toggle:focus::after
    {
        color: #000000 !important;
    }

    .page-head-dropdown-menu::-webkit-scrollbar {
        width: 7px;  
      }
    .page-head-dropdown-menu::-webkit-scrollbar-track {
    background: #ffffff;
    }
    .page-head-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: inherit;
    }

    .page-head-dropdown-menu
    {
        background-color: var(--container-background-color);
        font-size: 14px;
        text-align: left;
        width: 233px;
        max-height: 400px;
        overflow-y: auto;

       

        .page-head-dropdown-item{
            span{
                white-space: normal;
                float: left;
                width: 100%;
                height: auto;
                word-wrap: break-word;
            }
        }
        
        .dropdown-item
        {
            background-color: var(--container-background-color);
            color: var(--sidebar-normal-text-color);
            padding-left: 15px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            svg{
                display: none;
            }
        }

        .dropdown-item.active
        {
            background-color: var(--focus-background-color);
            color: var(--highlight-text-color);
            svg{
                display: flex;
            }

        }

        .dropdown-item:hover
        {
            background-color: var(--focus-background-color);
            color: var(--highlight-text-color);
        }
    }

}

.addOn-dropdown 
{
    .btn{
        box-shadow: 0 0;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
       
    }

    .dropdown-menu {
        min-width: 150px; 
        max-height: 300px;  

        overflow-y:auto;
    }

    .dropdown-toggle
    {
        border-radius: 8px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        background-color: var(--container-background-color);
        color: var(--sidebar-normal-text-color);
        border-color: var(--border-control-color);
        border-width: 1px;
        
    }

    .dropdown-toggle:focus
    {
        border-color: var(--border-control-select-color); 
    }

    .dropdown-toggle:focus::after
    {
        color: #000000 !important;
    }

}

.dropdown-button
{
    border-radius: 8px;
   
    .btn{
        box-shadow: 0 0;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        background-color: var(--highlight3-text-color);
        color: #ffffff;
    }

    .dropdown-button-btn
    {
        border-right-color: #ffffff !important ;
        border-right-width: 1px !important;
        border-right-style: solid !important;
    }

    .dropdown-button-btn:hover
    {
        border-right-color: #ffffff !important ;
        border-right-width: 1px !important;
        border-right-style: solid !important;
    }

    .dropdown-toggle
    {
        // border-radius: 8px;
        // height: 32px;      
        border-left-color: #ffffff !important ;
        border-left-width: 1px !important;
        border-left-style: solid !important;
    }

    .dropdown-toggle:focus::after
    {
        color:  #ffffff !important;
    }

    .dropdown-button-dropdown-menu
    {
        background-color: var(--container-background-color);
        font-size: 14px;
        text-align: left;
        
        .dropdown-item
        {
            background-color: var(--container-background-color);
            color: var(--sidebar-normal-text-color);
            padding-left: 15px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            svg{
                display: none;
            }
        }

        .dropdown-item.active
        {
            background-color: var(--focus-background-color);
            color: var(--highlight-text-color);
            svg{
                display: flex;
            }

        }

        .dropdown-item:hover
        {
            background-color: var(--focus-background-color);
            color: var(--highlight-text-color);
        }
    }
}

.dropdown-light
{
    .btn{
        box-shadow: 0 0;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
       
    }

    .dropdown-toggle
    {
        border-radius: 8px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        background-color: var(--container-background-color);
        color: var(--sidebar-normal-text-color);
        border-color: var(--border-control-color);
        border-width: 1px;
        
    }

    .dropdown-toggle:focus
    {
        border-color: var(--border-control-select-color);
        //color: #000000 !important;
       
    }

    .dropdown-toggle:focus::after
    {
        color: #000000 !important;
    }

    .dropdown-light-menu
    {
        background-color: var(--container-background-color);
        font-size: 14px;
        text-align: left;
        
        .dropdown-item
        {
            background-color: var(--container-background-color);
            color: var(--sidebar-normal-text-color);
            padding-left: 15px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            svg{
                display: none;
            }
        }

        .dropdown-item.active
        {
            background-color: var(--focus-background-color);
            color: var(--highlight-text-color);
            svg{
                display: flex;
            }

        }

        .dropdown-item:hover
        {
            background-color: var(--focus-background-color);
            color: var(--highlight-text-color);
        }
    }
}

.dropdown-shade {
  width: 29rem;
}

.bell-icon-fill{
    fill: #FFFFFF;
}

.bell-icon {
    fill: #9D9D9D;
}

.dropdown-notification.empty-noti{
    background-color: #FFFFFF;

    button[aria-expanded="true"]{
        background: #dadada;
        border-color: none;
     }
}

.dropdown-notification.fill-noti{
    background-color: #0171BB;
    border-radius: 10px;

    button[aria-expanded="true"]{
        background: #FFFFFF;
        border-color: none;
     }
}

.dropdown-notification
{
    border: none;
    width: 40px;
    height: 40px;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 6px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .btn {
        border: none;
    }

    :focus{
        background-color: transparent;
    }

    .btn:focus, .btn.focus {
        border: none;
        box-shadow: none !important;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-toggle.btn{
        width: 40px;
        height: 40px;
        padding: 1px;
        fill: #9D9D9D;
    }



    .dropdown-header {
        padding-left: 16px;
        padding-right: 16px;
        font-weight: 500;
        font-size: 18px;
        align-items: center;
        color: black;
    }

    .dropdown-menu {
        max-height: 410px;
        width: 336px;
        padding: 0px 0px 0px 0px;
        background: white;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        overflow:auto;
    }

    .dropdown-item {
        padding-left: 16px;
        font-weight: normal;
        font-size: 16px;
        align-items: center;
        color: black;
    }

    .dropdown-notification-menu__time{
        font-size: 14px;
        font-weight: 400 ;
        color: #0171BB;
    }

    .dropdown-notification-menu__time.disable, .dropdown-notification-menu__title.disable{
        color: #9D9D9D;
    }

    .dropdown-notification-menu__title {
        font-weight: 400;
        font-size: 16px;
        align-items: center;
        color: black;
        
        white-space: normal;
        float: left;
        width: 100%;
        height: auto;
        word-wrap: break-word;
        white-space: pre-wrap;
    }
    
    .dropdown-notification__dot {
        position: absolute;
        right: 16px;
        width: 8px;
        height: 8px;
        background: #0171BB;
        border-radius: 50%;
        align-self: center;
    }

    .dropdown-notification__dot.disable{
        display: none;
    }

    .dropdown-divider { 
        margin: 0px 16px 0px 16px;
        overflow: hidden;
        border: 1px solid rgba(202, 202, 202, 0.5);
    }

}


.noti-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 90%;
    font-weight: 500;
    line-height: 1;
    top: -5px;
    color:white;
    background-color: var(--danger-text-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}